<template>
  <div class="ui-switch" :style="{'--switch-duration': duration+'ms'}">
    <transition name="uiswitch" :duration="duration">
      <div v-if="value" key="on" @click.stop="setValue(false)">
        <slot name="on"></slot>
      </div>
      <div v-else key="off" @click.stop="setValue(true)">
        <slot name="off"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ui-switch",

  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },

    duration: {
      type: Number,
      required: false,
      default: 250
    }
  },

  methods: {
    setValue(newValue) {
      this.$emit('input', newValue);
    }
  }
}
</script>

<style lang="scss">
.ui-switch {
  position: relative;
}

.uiswitch-leave-active {
  position: absolute;
  top: 0;
  left: 0;
}


// Transicion para elementos ui-item
.uiswitch-enter-active,
.uiswitch-leave-active {

  .item-icon {
    perspective: 256px;

    & > * {
      transition: transform var(--switch-duration);
      transform-style: preserve-3d;
      backface-visibility: hidden;
    }
  }

  .item-body {
    transition: opacity var(--switch-duration);
  }
}

.uiswitch-enter,
.uiswitch-leave-to {

  .item-icon {
    & > * {
      transform: rotateY(180deg);
    }
  }

  .item-body {
    opacity: 0;
  }
}
</style>